import { Text } from "@clipboard-health/ui-react";
import { CardContent, Stack } from "@mui/material";
import type { Facility } from "@src/appV2/Facilities/types";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { Button } from "../../components/Button";
import { SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";
import { WorkplaceHighlightPills } from "../../Workplace/Highlights/Pills";
import { useWorkplacePolicyHighlights } from "../../Workplace/Highlights/useWorkplacePolicyHighlights";
import { useWorkplaceReviewHighlights } from "../../Workplace/Highlights/useWorkplaceReviewHighlights";
import { ShiftBottomSheetInnerCard } from "./InnerCard";

interface ShiftBottomSheetWorkplaceHighlightsCardProps {
  workplace: Facility;
}

/**
 * When there are fewer than 5 highlights, display each one on its own row to improve visual balance.
 * This prevents the card from looking too empty with sparse content.
 */
const HIGHLIGHTS_WRAP_THRESHOLD = 5;

export function ShiftBottomSheetWorkplaceHighlightsCard(
  props: ShiftBottomSheetWorkplaceHighlightsCardProps
) {
  const { workplace } = props;
  const {
    userId: workplaceId,
    lateCancellation,
    requiresLunchBreak,
    providesRestBreaks,
    name,
  } = workplace;

  const { highlights: policyHighlights, isLoading: isPolicyHighlightsLoading } =
    useWorkplacePolicyHighlights({
      workplaceId,
      lateCancellation,
      requiresLunchBreak,
      providesRestBreaks,
    });

  const { highlights: reviewHighlights, isLoading: isReviewHighlightsLoading } =
    useWorkplaceReviewHighlights({ workplaceId });

  const { generateModalPath } = useWorkplaceModalsDataContext();

  const highlights = [...reviewHighlights, ...policyHighlights];

  return (
    <ShiftBottomSheetInnerCard>
      <CardContent sx={{ padding: 6, height: "100%" }}>
        <Stack sx={{ height: "100%", gap: 7 }}>
          <Text semibold variant="body1">
            {name} Highlights
          </Text>

          <WorkplaceHighlightPills
            highlights={highlights}
            isLoading={isReviewHighlightsLoading || isPolicyHighlightsLoading}
            wrapperSx={{
              overflow: "auto",
              maxWidth: highlights.length > HIGHLIGHTS_WRAP_THRESHOLD ? "100%" : "min-content",
            }}
          />

          <Button
            fullWidth
            variant="outlined"
            size="medium"
            href={generateModalPath(SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH, {
              workplaceId: String(workplaceId),
            })}
            sx={{ marginTop: "auto" }}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.VIEWED_FACILITY_PROFILE, {
                workplaceId,
                trigger: "Shift Bottom Sheet Highlights Card",
              });
            }}
          >
            View workplace profile
          </Button>
        </Stack>
      </CardContent>
    </ShiftBottomSheetInnerCard>
  );
}
