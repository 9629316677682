import { isDefined } from "@clipboard-health/util-ts";
import type { Facility } from "@src/appV2/Facilities/types";
import {
  type BookabilityStatusItem,
  BookabilityUnmetCriteria,
} from "@src/appV2/OpenShifts/ShiftAction";
import { hasUnmetBookabilityCriteria } from "@src/appV2/OpenShifts/ShiftAction/hasUnmetBookabilityCriteria";

import { ShiftBottomSheetBlockingDocumentsCardsContainer } from "./BlockingDocumentsCards/Container";
import { type ShiftBlockingDocuments } from "./BlockingDocumentsCards/types";
import { ShiftBottomSheetCancellationPoliciesCard } from "./CancellationPoliciesCard";
import { ShiftBottomSheetCardsWrapper } from "./CardsWrapper";
import { ShiftBottomSheetHourRestrictionsCard } from "./FacilityHourRestrictionsCard";
import { ShiftBottomSheetFacilityMapLocationCard } from "./FacilityMapLocationCard";
import { ShiftBottomSheetFacilityPhotosCard } from "./FacilityPhotosCard";
import { ShiftBottomSheetQuizCard } from "./QuizCard";
import { ShiftBottomSetupPaymentsCard } from "./SetupPaymentsCard";
import { ShiftBottomSignAgreementCard } from "./SignAgreementCard";
import { ShiftBottomSheetWorkplaceHighlightsCard } from "./WorkplaceHighlightsCard";
import { ShiftBottomSheetWorkplaceReviewsCard } from "./WorkplaceReviewsCard";

interface ShiftBottomSheetSheetContentProps {
  shiftId: string;
  qualificationName: string;
  workplace: Facility;
  bookabilityStatus: BookabilityStatusItem;
  blockingDocuments: ShiftBlockingDocuments;
  hasHoursRestrictionConflict: boolean;
}

export function ShiftBottomSheetContent(props: ShiftBottomSheetSheetContentProps) {
  const {
    shiftId,
    qualificationName,
    workplace,
    bookabilityStatus,
    blockingDocuments,
    hasHoursRestrictionConflict,
  } = props;

  const { missingDocumentsSet, expiringDocumentsSet, pendingDocumentsSet } = blockingDocuments;

  const {
    userId: workplaceId,
    name: workplaceName,
    lateCancellation,
    sentHomeChargeHours,
    geoLocation,
    maxAllowedWorkConsecutiveHours,
    maxAllowedWorkHoursPerWeek,
    preventDoubleShifts,
  } = workplace;

  const hasHourRestrictions =
    isDefined(maxAllowedWorkConsecutiveHours) || isDefined(maxAllowedWorkHoursPerWeek);

  const { period: lateCancellationPeriod, feeHours: lateCancellationFeeHours } =
    lateCancellation ?? {};

  const hasQuizToPass = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES,
  ]);

  const hasAgreementToSign = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_UNSIGNED_AGREEMENT,
  ]);

  const hasPaymentsDisabled = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_PAYMENTS_DISABLED,
  ]);

  const hasBlockingDocuments =
    missingDocumentsSet.size + expiringDocumentsSet.size + pendingDocumentsSet.size > 0;

  return (
    <ShiftBottomSheetCardsWrapper>
      {hasBlockingDocuments && (
        <ShiftBottomSheetBlockingDocumentsCardsContainer
          shiftId={shiftId}
          qualificationName={qualificationName}
          workplaceId={workplaceId}
          workplaceName={workplaceName}
          blockingDocuments={blockingDocuments}
          bookabilityStatus={bookabilityStatus}
        />
      )}
      {hasQuizToPass && <ShiftBottomSheetQuizCard shiftId={shiftId} />}
      {hasAgreementToSign && <ShiftBottomSignAgreementCard shiftId={shiftId} />}
      {hasPaymentsDisabled && <ShiftBottomSetupPaymentsCard shiftId={shiftId} />}
      {hasHourRestrictions && hasHoursRestrictionConflict && (
        <ShiftBottomSheetHourRestrictionsCard
          maxAllowedWorkConsecutiveHours={maxAllowedWorkConsecutiveHours}
          maxAllowedWorkHoursPerWeek={maxAllowedWorkHoursPerWeek}
          preventDoubleShifts={preventDoubleShifts}
        />
      )}

      <ShiftBottomSheetWorkplaceHighlightsCard workplace={workplace} />
      <ShiftBottomSheetCancellationPoliciesCard
        lateCancellationPeriod={lateCancellationPeriod ?? 0}
        lateCancellationFeeHours={lateCancellationFeeHours ?? 0}
        sentHomeChargeHours={sentHomeChargeHours ?? 0}
        workplaceId={String(workplaceId)}
        workplace={workplace}
      />
      <ShiftBottomSheetFacilityPhotosCard workplaceId={workplaceId} />
      <ShiftBottomSheetWorkplaceReviewsCard workplace={workplace} />
      <ShiftBottomSheetFacilityMapLocationCard
        workplaceId={workplaceId ?? ""}
        workplaceName={workplaceName}
        facilityLocation={geoLocation}
      />
    </ShiftBottomSheetCardsWrapper>
  );
}
