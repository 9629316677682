import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { WorkplaceShiftRateNegotiability } from "@src/appV2/OpenShifts/RateNegotiation/types";
import { type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";
import { parseISO } from "date-fns";

import { useShiftDiscoveryUserFiltersContext } from "../../ShiftDiscovery/Filters/useUserFiltersContext";
import { useGetMissingRequirementsForShift } from "../Booking/useGetMissingRequirementsForShift";
import { useHasHourRestrictionConflict } from "../HourRestrictionConflicts/hooks/useHasHourRestrictionConflict";
import { isShiftRateNegotiationEnabled } from "../RateNegotiation/isShiftRateNegotiationEnabled";
import { useShiftRateNegotiationConditions } from "../RateNegotiation/useShiftRateNegotiationConditions";
import { type ShiftWithType } from "../types";
import { ShiftBottomSheetFooter } from "./Footer";
import { ShiftBottomSheetHeader } from "./Header";
import { ShiftBottomSheetContent } from "./SheetContent";
import { ShiftBottomSheetSkeleton } from "./SheetSkeleton";
import { ShiftBottomSheetWrapper, type ShiftBottomSheetWrapperProps } from "./Wrapper";

interface ShiftBottomSheetProps extends Pick<ShiftBottomSheetWrapperProps, "modalState"> {
  shift?: ShiftWithType;
  isShiftLoading: boolean;
  isError: boolean;
  bookabilityStatus: BookabilityStatusItem;
}

/**
 * A bottom sheet container for displaying shift details.
 *
 * The shift data can come from two sources:
 * 1. Directly via props when opened from a shift card
 * 2. Fetched from the API using the shift ID when:
 *    - Navigating between workplace views and browsing different shifts
 *    - Using back button in workplace modals going back to various shifts visited earlier
 *    - Opening a shift directly via URL
 */
export function ShiftBottomSheet(props: ShiftBottomSheetProps) {
  const { shift, modalState, isShiftLoading, isError, bookabilityStatus } = props;

  const { name: shiftTimeSlot, type: shiftType, facility: workplace } = shift ?? {};

  const { licenses: selectedLicensesFilter, availableLicenses } =
    useShiftDiscoveryUserFiltersContext();

  const {
    missingDocumentsSet,
    pendingDocumentsSet,
    expiringDocumentsSet,
    isLoading: isMissingDocumentsLoading,
  } = useGetMissingRequirementsForShift({
    shift,
    workplace,
    bookabilityStatus,
    selectedWorkerQualifications:
      selectedLicensesFilter.length > 0 ? selectedLicensesFilter : availableLicenses,
  });

  const blockingDocuments = { missingDocumentsSet, expiringDocumentsSet, pendingDocumentsSet };
  const hasBlockingDocuments =
    missingDocumentsSet.size + expiringDocumentsSet.size + pendingDocumentsSet.size > 0;

  const { hasHoursRestrictionConflict, isLoading: isHasHoursRestrictionConflictLoading } =
    useHasHourRestrictionConflict({
      shift,
    });

  const isLoading =
    isShiftLoading || isMissingDocumentsLoading || isHasHoursRestrictionConflictLoading;

  const rateNegotiationConditions = useShiftRateNegotiationConditions();
  const isRateNegotiationEnabled =
    isDefined(shift) &&
    isShiftRateNegotiationEnabled(
      {
        shiftStart: shift.start,
        workplaceShiftRateNegotiability:
          shift.facility.rateNegotiation ?? WorkplaceShiftRateNegotiability.OFF,
        workplaceId: shift.facility.userId,
        workplaceState: shift.facility.fullAddress?.state,
        workplaceMsa: shift.facility.fullAddress?.metropolitanStatisticalArea,
        isShiftChargeUpdatedManually: shift.isChargeUpdatedManually,
      },
      rateNegotiationConditions
    );

  return (
    <ShiftBottomSheetWrapper
      shiftType={shiftType}
      shiftTimeSlot={shiftTimeSlot}
      modalState={modalState}
      footer={
        <ShiftBottomSheetFooter
          shiftId={shift?._id}
          shiftOfferId={shift?.offer?.id}
          workplaceId={shift?.facility.userId}
          hasBlockingDocuments={hasBlockingDocuments}
          isLoading={isLoading}
          isError={isError}
          modalState={modalState}
          bookabilityStatus={bookabilityStatus}
          isRateNegotiationEnabled={isRateNegotiationEnabled}
          shiftRateNegotiation={shift?.rateNegotiation}
        />
      }
    >
      <ShiftBottomSheetHeader
        isLoading={isShiftLoading && !isDefined(shift)}
        timeSlot={shift?.name}
        qualificationName={shift?.agentReq}
        offerPay={shift?.offer?.pay.value}
        finalPay={shift?.finalPay}
        originalAmount={shift?.originalAmount ?? undefined}
        timezone={shift?.facility.tmz}
        dateRange={
          isDefined(shift?.start) && isDefined(shift?.end)
            ? { startDate: parseISO(shift.start), endDate: parseISO(shift.end) }
            : undefined
        }
        durationInHours={shift?.time}
        workplaceName={shift?.facility.name}
        workplaceId={shift?.facility.userId}
      />

      {isDefined(shift) && isDefined(bookabilityStatus) && !isLoading && (
        <ShiftBottomSheetContent
          shiftId={shift._id}
          qualificationName={shift.agentReq}
          workplace={shift.facility}
          blockingDocuments={blockingDocuments}
          bookabilityStatus={bookabilityStatus}
          hasHoursRestrictionConflict={hasHoursRestrictionConflict}
        />
      )}

      {isLoading && <ShiftBottomSheetSkeleton />}

      {isError && (
        // TODO: Add proper error state
        <Text
          variant="h3"
          sx={(theme) => ({
            textAlign: "center",
            padding: 10,
            color: theme.palette.intent?.error.text,
          })}
        >
          Could not load shift details
        </Text>
      )}
    </ShiftBottomSheetWrapper>
  );
}
