import { mergeSxProps } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { type Theme } from "@mui/material";

import { BottomSheet, type BottomSheetProps } from "../../components/BottomSheet";
import { ShiftType } from "../constants";
import { getGradientByTimeSlot } from "../getGradientByTimeSlot";
import { type TimeSlot } from "../Open/types";

export interface ShiftBottomSheetWrapperProps extends BottomSheetProps {
  shiftType?: ShiftType;
  shiftTimeSlot?: TimeSlot;
}

function resolveBackground(theme: Theme, shiftType?: ShiftType, shiftTimeSlot?: TimeSlot) {
  const defaultGradient = theme.palette.shift?.day.gradient;

  if (!isDefined(shiftType) || !isDefined(shiftTimeSlot)) {
    return defaultGradient;
  }

  switch (shiftType) {
    case ShiftType.REGULAR: {
      return getGradientByTimeSlot({ timeSlot: shiftTimeSlot, theme }) ?? defaultGradient;
    }

    case ShiftType.URGENT: {
      return theme.palette.shift?.urgent.gradient;
    }

    case ShiftType.PRIORITY:
    case ShiftType.A_TEAM: {
      return theme.palette.shift?.priority.gradient;
    }

    default: {
      return defaultGradient;
    }
  }
}

export function ShiftBottomSheetWrapper(props: ShiftBottomSheetWrapperProps) {
  const { shiftType, shiftTimeSlot, paperSx, ...restProps } = props;

  return (
    <BottomSheet
      {...restProps}
      paperSx={mergeSxProps(
        (theme) => ({ background: resolveBackground(theme, shiftType, shiftTimeSlot) }),
        paperSx
      )}
    />
  );
}
