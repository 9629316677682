import { CardContent, Skeleton } from "@mui/material";

import { PillSkeleton } from "../../components/PillSkeleton";
import { ShiftBottomSheetCardsWrapper } from "./CardsWrapper";
import { ShiftBottomSheetInnerCard } from "./InnerCard";

export function ShiftBottomSheetSkeleton() {
  return (
    <ShiftBottomSheetCardsWrapper>
      <ShiftBottomSheetInnerCard>
        <CardContent
          sx={{
            padding: 6,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 7,
          }}
        >
          <PillSkeleton />
          {/* TODO: This needs List skeleton which will be introduced as follow up */}
          <Skeleton variant="text" width="70%" height="25%" />
          <Skeleton variant="text" width="85%" height="25%" />
          <Skeleton variant="text" width="65%" height="25%" />
        </CardContent>
      </ShiftBottomSheetInnerCard>

      <ShiftBottomSheetInnerCard>
        <CardContent
          sx={{
            padding: 6,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 7,
          }}
        >
          <PillSkeleton />
          <Skeleton variant="text" width="60%" height="25%" />
          <Skeleton variant="text" width="60%" height="25%" />
          <Skeleton variant="text" width="60%" height="25%" />
        </CardContent>
      </ShiftBottomSheetInnerCard>
    </ShiftBottomSheetCardsWrapper>
  );
}
