import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent, Skeleton, Stack } from "@mui/material";
import { useFacilityNotes } from "@src/appV2/Facilities/api/useFacilityNotes";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useDeviceGeoLocationIfAllowed } from "@src/appV2/Location";
import { useIsDeviceLocationPermissionGranted } from "@src/appV2/Location/deviceLocation/geoLocation";
import { BottomSheet } from "@src/appV2/redesign/components/BottomSheet";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { usePostPolicyAcknowledgement } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/usePostPolicyAcknowledgement";
import { NoteAcknowledgementAction } from "@src/appV2/Shifts/MandatoryBreakPolicy/types";
import { matchPath, useLocation } from "react-router-dom";

import { Illustration } from "../../components/Illustration";
import { LoadingButton } from "../../components/LoadingButton";
import {
  SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH,
  SHIFT_DISCOVERY_URGENT_PATH,
  SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH,
} from "../../ShiftDiscovery/paths";
import { type ShiftBreakModalRoutePathParams } from "../../ShiftDiscovery/types";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { useBookShift } from "../Booking/useBookShift";

interface BreakConfirmationBottomSheetProps {
  shiftId: string;
  shiftOfferId?: string;
  workplaceId?: string;
  modalState: UseModalState;
}

export function BreakConfirmationBottomSheet(props: BreakConfirmationBottomSheetProps) {
  const { modalState, shiftId, shiftOfferId, workplaceId } = props;
  const { navigateToModal, baseNavigationPath } = useShiftModalsDataContext();
  const { pathname } = useLocation();
  const match = matchPath<ShiftBreakModalRoutePathParams>(pathname, {
    path: [
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH}/${SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_URGENT_PATH}/${SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH}`,
    ],
    exact: true,
    strict: false,
  });
  const { mutateAsync: postBreakPolicyAcknowledgement, isLoading: isAcknowledgingBreakPolicy } =
    usePostPolicyAcknowledgement();

  const noteId = match?.params.noteId ?? "";

  const { data: facilityNotesData, isInitialLoading: isFacilityNotesLoading } = useFacilityNotes(
    workplaceId ?? "",
    { enabled: isDefined(workplaceId) }
  );

  const breakPolicyNote = facilityNotesData?.find((note) => note.noteId === noteId);

  // We need the device's geolocation, otherwise we can't let the user claim the shift
  const {
    data: isDeviceLocationPermissionGranted,
    isLoading: isDeviceLocationPermissionGrantedLoading,
  } = useIsDeviceLocationPermissionGranted();

  const geolocationIsUnavailable = !isDeviceLocationPermissionGranted && isCapacitorPlatform();

  const { data: deviceGeolocation, isInitialLoading: isDeviceGeolocationLoading } =
    useDeviceGeoLocationIfAllowed({
      enabled: !geolocationIsUnavailable,
    });

  const { attemptBookingShift, isBooking } = useBookShift({
    shiftId,
    shiftOfferId,
    onBook: () => {
      navigateToModal(SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH, { shiftId });
    },
  });

  const isLoading =
    isBooking ||
    isAcknowledgingBreakPolicy ||
    isDeviceGeolocationLoading ||
    isDeviceLocationPermissionGrantedLoading ||
    isFacilityNotesLoading;

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            logEvent(APP_V2_USER_EVENTS.MANDATORY_BREAK_POLICY_CANCELLED, { shiftId });
            modalState.closeModal();
          }}
        >
          <LoadingButton
            fullWidth
            isLoading={isLoading}
            size="large"
            variant="contained"
            onClick={async () => {
              logEvent(APP_V2_USER_EVENTS.MANDATORY_BREAK_POLICY_ACCEPTED, { shiftId });
              await postBreakPolicyAcknowledgement({
                policyAcknowledgementAction: NoteAcknowledgementAction.BOOK_SHIFT,
                noteId,
              }).then(async () => {
                await attemptBookingShift(deviceGeolocation?.geoLocation);
              });
            }}
          >
            Continue with booking
          </LoadingButton>
        </DialogFooter>
      }
    >
      <DialogContent>
        <Stack direction="column" alignItems="center" spacing={8}>
          <Illustration type="break" />
          <Title variant="h3" component="h3" sx={{ textAlign: "center" }}>
            30-min break required
          </Title>
          <Stack direction="column" spacing={4}>
            <Text color={(theme) => theme.palette.text.secondary} variant="body1">
              This facility has the following break policy:
            </Text>
            {isFacilityNotesLoading && (
              <Stack direction="column" spacing={1}>
                <Skeleton variant="text" height={24} />
                <Skeleton variant="text" height={24} />
                <Skeleton variant="text" height={24} />
              </Stack>
            )}
            {!isFacilityNotesLoading && (
              <Text
                color={(theme) => theme.palette.text.secondary}
                variant="body1"
                sx={{ fontStyle: "italic", lineHeight: 1.25 }}
              >
                {breakPolicyNote?.note?.trim()}
              </Text>
            )}
            <Text
              color={(theme) => theme.palette.text.secondary}
              variant="body1"
              sx={{ lineHeight: 1.25 }}
            >
              This facility requires a mandatory 30-min unpaid break if you work longer than 6
              hours. If you take an incomplete break, you will need to submit additional
              documentation. Payment for worked-through breaks may take up to three business days to
              process.
            </Text>
          </Stack>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
